import React from "react";

export default function Icon(props) {
  switch (props.name) {
    case "close":
      return (
        <svg
          x="0px"
          y="0px"
          width="24px"
          height="24px"
          viewBox="0 0 31.11 31.11"
          enableBackground="new 0 0 31.11 31.11"
        >
          <polygon
            fill={props.color}
            points="31.11,1.41 29.7,0 15.56,14.14 1.41,0 0,1.41 14.14,15.56 0,29.7 1.41,31.11 15.56,16.97   29.7,31.11 31.11,29.7 16.97,15.56 "
          />
        </svg>
      );
    case "github":
      return (
        <svg
          id="Layer_1"
          fill={props.color}
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 120.78 117.79"
        >
          <title>GitHub-Mark</title>
          <path d="M60.39,0A60.39,60.39,0,0,0,41.3,117.69c3,.56,4.13-1.31,4.13-2.91,0-1.44-.06-6.2-.08-11.24C28.54,107.19,25,96.42,25,96.42c-2.75-7-6.7-8.84-6.7-8.84-5.48-3.75.41-3.67.41-3.67,6.06.43,9.26,6.22,9.26,6.22,5.39,9.23,14.13,6.56,17.57,5,.54-3.9,2.11-6.57,3.83-8.08C36,85.55,21.85,80.37,21.85,57.23A23.37,23.37,0,0,1,28.08,41c-.63-1.52-2.69-7.66.58-16,0,0,5.07-1.62,16.61,6.19a57.26,57.26,0,0,1,30.24,0C87,23.42,92.11,25,92.11,25c3.29,8.32,1.22,14.46.59,16a23.32,23.32,0,0,1,6.21,16.21c0,23.19-14.13,28.3-27.57,29.8,2.17,1.87,4.1,5.55,4.1,11.18,0,8.08-.07,14.58-.07,16.57,0,1.61,1.09,3.49,4.15,2.9A60.4,60.4,0,0,0,60.39,0Z" />
          <path d="M22.87,86.7c-.13.3-.61.39-1,.18s-.68-.61-.54-.91.6-.39,1-.19.69.61.54.91Zm-.74-.55" />
          <path d="M25.32,89.43c-.29.27-.85.14-1.23-.28a.92.92,0,0,1-.18-1.25c.3-.27.84-.14,1.24.28s.47,1,.17,1.25Zm-.58-.62" />
          <path d="M27.7,92.91c-.37.26-1,0-1.35-.52s-.37-1.18,0-1.44,1,0,1.35.51.37,1.19,0,1.45Zm0,0" />
          <path d="M31,96.27c-.33.37-1,.27-1.55-.23a1.16,1.16,0,0,1-.34-1.54c.34-.37,1-.26,1.56.23s.68,1.18.33,1.54Zm0,0" />
          <path d="M35.46,98.22c-.15.47-.83.69-1.51.49s-1.13-.76-1-1.24.82-.7,1.51-.49,1.13.76,1,1.24Zm0,0" />
          <path d="M40.4,98.58c0,.5-.56.91-1.28.92s-1.31-.39-1.31-.88.57-.91,1.29-.92,1.31.39,1.31.88Zm0,0" />
          <path d="M45,97.8c.09.49-.41,1-1.13,1.12s-1.35-.17-1.44-.65.42-1,1.12-1.13,1.35.17,1.44.66Zm0,0" />
        </svg>
      );
    case "codepen":
      return (
        <svg fill={props.color} viewBox="-1 0 122 120">
          <path
            className="outer-ring"
            d="M60,0C26.863,0,0,26.863,0,60c0,33.138,26.863,60,60,60c33.137,0,60-26.861,60-60C120,26.863,93.138,0,60,0z M60,110.146
		C32.35,110.146,9.854,87.65,9.854,60S32.35,9.854,60,9.854c27.65,0,50.145,22.496,50.145,50.146S87.65,110.146,60,110.146z"
          />
          <path
            className="inner-box"
            d="M97.071,48.281c-0.007-0.047-0.019-0.092-0.026-0.139c-0.016-0.09-0.032-0.18-0.056-0.267
		c-0.014-0.053-0.033-0.104-0.05-0.154c-0.025-0.078-0.051-0.156-0.082-0.232c-0.021-0.053-0.047-0.105-0.071-0.156
		c-0.033-0.072-0.068-0.142-0.108-0.21c-0.029-0.051-0.061-0.1-0.091-0.148c-0.043-0.066-0.087-0.131-0.135-0.193
		c-0.035-0.047-0.072-0.093-0.109-0.138c-0.051-0.059-0.104-0.117-0.159-0.172c-0.042-0.043-0.083-0.086-0.127-0.125
		c-0.059-0.053-0.119-0.104-0.18-0.152c-0.048-0.037-0.095-0.074-0.145-0.109c-0.019-0.012-0.035-0.027-0.053-0.039L61.769,23.438
		c-1.071-0.714-2.466-0.714-3.537,0L24.321,46.045c-0.018,0.012-0.034,0.027-0.053,0.039c-0.05,0.035-0.097,0.072-0.144,0.109
		c-0.062,0.049-0.123,0.1-0.181,0.152c-0.045,0.039-0.086,0.082-0.128,0.125c-0.055,0.055-0.108,0.113-0.158,0.172
		c-0.038,0.045-0.075,0.091-0.11,0.138c-0.047,0.062-0.092,0.127-0.134,0.193c-0.032,0.049-0.062,0.098-0.092,0.148
		c-0.039,0.068-0.074,0.139-0.108,0.21c-0.024,0.051-0.049,0.104-0.071,0.156c-0.031,0.076-0.057,0.154-0.082,0.232
		c-0.017,0.051-0.035,0.102-0.05,0.154c-0.023,0.087-0.039,0.177-0.056,0.267c-0.008,0.047-0.02,0.092-0.025,0.139
		c-0.019,0.137-0.029,0.275-0.029,0.416v22.607c0,0.141,0.011,0.279,0.029,0.418c0.006,0.045,0.018,0.092,0.025,0.137
		c0.017,0.09,0.032,0.18,0.056,0.268c0.015,0.053,0.033,0.104,0.05,0.154c0.025,0.078,0.051,0.155,0.082,0.233
		c0.021,0.053,0.047,0.104,0.071,0.154c0.034,0.072,0.069,0.143,0.108,0.213c0.029,0.049,0.06,0.098,0.092,0.146
		c0.042,0.066,0.087,0.131,0.134,0.193c0.035,0.049,0.072,0.094,0.11,0.139c0.05,0.059,0.103,0.117,0.158,0.172
		c0.042,0.043,0.083,0.086,0.128,0.124c0.058,0.053,0.118,0.104,0.181,0.152c0.047,0.037,0.094,0.074,0.144,0.109
		c0.019,0.012,0.035,0.027,0.053,0.039l33.911,22.607c0.536,0.357,1.152,0.537,1.769,0.537c0.616,0,1.233-0.18,1.768-0.537
		L95.68,73.956c0.018-0.012,0.034-0.027,0.053-0.039c0.05-0.035,0.097-0.072,0.145-0.109c0.061-0.049,0.121-0.1,0.18-0.152
		c0.044-0.038,0.085-0.081,0.127-0.124c0.056-0.055,0.108-0.113,0.159-0.172c0.037-0.045,0.074-0.09,0.109-0.139
		c0.048-0.062,0.092-0.127,0.135-0.193c0.03-0.049,0.062-0.098,0.091-0.146c0.04-0.07,0.075-0.141,0.108-0.213
		c0.024-0.051,0.05-0.102,0.071-0.154c0.031-0.078,0.057-0.155,0.082-0.233c0.017-0.051,0.036-0.102,0.05-0.154
		c0.023-0.088,0.04-0.178,0.056-0.268c0.008-0.045,0.02-0.092,0.026-0.137c0.018-0.139,0.028-0.277,0.028-0.418V48.697
		C97.1,48.557,97.089,48.418,97.071,48.281z M63.188,32.048L88.17,48.701l-11.158,7.465l-13.823-9.247V32.048z M56.812,32.048
		v14.871l-13.822,9.247l-11.159-7.465L56.812,32.048z M29.278,54.665L37.255,60l-7.977,5.335V54.665z M56.812,87.953L31.831,71.3
		l11.159-7.463l13.822,9.245V87.953z M60,67.543L48.723,60L60,52.458L71.276,60L60,67.543z M63.188,87.953V73.082l13.823-9.245
		L88.17,71.3L63.188,87.953z M90.723,65.336L82.746,60l7.977-5.335V65.336z"
          />
        </svg>
      );
    case "twitter":
      return (
        <svg
          fill={props.color}
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 40 40"
        >
          <g>
            <path d="m37.7 9.1q-1.5 2.2-3.7 3.7 0.1 0.3 0.1 1 0 2.9-0.9 5.8t-2.6 5.5-4.1 4.7-5.7 3.3-7.2 1.2q-6.1 0-11.1-3.3 0.8 0.1 1.7 0.1 5 0 9-3-2.4-0.1-4.2-1.5t-2.6-3.5q0.8 0.1 1.4 0.1 1 0 1.9-0.3-2.5-0.5-4.1-2.5t-1.7-4.6v0q1.5 0.8 3.3 0.9-1.5-1-2.4-2.6t-0.8-3.4q0-2 0.9-3.7 2.7 3.4 6.6 5.4t8.3 2.2q-0.2-0.9-0.2-1.7 0-3 2.1-5.1t5.1-2.1q3.2 0 5.3 2.3 2.4-0.5 4.6-1.7-0.8 2.5-3.2 3.9 2.1-0.2 4.2-1.1z"></path>
          </g>
        </svg>
      );
    case "linkedin":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill={props.color}
        >
          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
        </svg>
      );
    case "facebook":
      return (
        <svg
          version="1.1"
          fill={props.color}
          id="Capa_1"
          viewBox="0 0 96.124 96.123"
        >
          <g>
            <path
              d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803
			c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654
			c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246
			c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z"
            />
          </g>
        </svg>
      );
    case "facebook-like":
      return (
        <svg
          fill={props.color}
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 300 231.82"
        >
          <path
            class="cls-1"
            d="M203.43,135.92l-3.71,1.15,2.34,3.11A8.08,8.08,0,0,1,196.94,153l-3.72.62L195,157a8.08,8.08,0,0,1-6.67,11.79l-46.73,0a17.35,17.35,0,0,1-9.93-3.63,10.64,10.64,0,0,0-2.59-1.7l-1-.46V119.82l4.87-4.17,16.44-30.48a13.5,13.5,0,0,0,1.93-8.07L150,60a7.49,7.49,0,0,1,3.73-1c7.1,0,13.18,11.34,13.18,20.63a26.16,26.16,0,0,1-.29,3.85L163.75,102h35.64a8.76,8.76,0,0,1,6.17,2.61,7.77,7.77,0,0,1,2.35,5.55,8,8,0,0,1-3.22,6.4l-3.31,2.51,3.62,2.05a8.11,8.11,0,0,1-1.57,14.8Z"
          />
          <path
            class="cls-2"
            d="M88.53,111.32a.17.17,0,0,0-.17.16V175a.17.17,0,0,0,.17.16h36.59a.18.18,0,0,0,.18-.16V111.48a.18.18,0,0,0-.18-.16Z"
          />
        </svg>
      );
    case "mail":
      return (
        <svg viewBox="0 0 20 20" version="1.1">
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill={props.color}
            fill-rule="evenodd"
          >
            <g id="icon-shape">
              <path
                d="M14.8780488,10.097561 L20,14 L20,16 L13.627451,11.0980392 L10,14 L6.37254902,11.0980392 L0,16 L0,14 L5.12195122,10.097561 L0,6 L0,4 L10,12 L20,4 L20,6 L14.8780488,10.097561 Z M18.0092049,2 C19.1086907,2 20,2.89451376 20,3.99406028 L20,16.0059397 C20,17.1072288 19.1017876,18 18.0092049,18 L1.99079514,18 C0.891309342,18 0,17.1054862 0,16.0059397 L0,3.99406028 C0,2.8927712 0.898212381,2 1.99079514,2 L18.0092049,2 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </svg>
      );
    case "zoom-out":
      return (
        <svg viewBox="0 0 20 20" version="1.1">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill={props.color}
            fillRule="evenodd"
          >
            <g id="icon-shape">
              <path
                d="M12.9056439,14.3198574 C11.5509601,15.3729184 9.84871145,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,9.84871145 15.3729184,11.5509601 14.3198574,12.9056439 L19.6568542,18.2426407 L18.2426407,19.6568542 L12.9056439,14.3198574 Z M8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 Z M5,7 L11,7 L11,9 L5,9 L5,7 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </svg>
      );
    default:
      return "";
  }
}
