// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-index-js": () => import("./../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-new-regret-image-js": () => import("./../src/pages/admin/new-regret-image.js" /* webpackChunkName: "component---src-pages-admin-new-regret-image-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-share-regret-js": () => import("./../src/pages/share-regret.js" /* webpackChunkName: "component---src-pages-share-regret-js" */),
  "component---src-pages-shareimage-jsx": () => import("./../src/pages/shareimage.jsx" /* webpackChunkName: "component---src-pages-shareimage-jsx" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-regret-js": () => import("./../src/templates/regret.js" /* webpackChunkName: "component---src-templates-regret-js" */)
}

